.button-89 {
  --b: 3px; /* border thickness */
  --s: 0.45em; /* size of the corner */
  --color: #373b44;
  padding: 2rem 4rem;
  font-weight: 600;
  /* padding: calc(0.5em + var(--s)) calc(0.9em + var(--s)); */
  color: var(--color);
  --_p: var(--s);
  background: conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p)
    var(--_p) / calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: 0.6em;
  font-size: 16px;
  cursor: pointer;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* .button-89:hover,
.button-89:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: 0.05em;
} */

/* .button-89:active {
  background: var(--color);
  color: #fff;
} */
.resourceNotFoundtitle {
  font-weight: bolder;
  font-size: 2rem;
  margin: 0 0;
  color: #ffdd83;
}
.resourceNotFoundsubtitle {
  margin: 0 0;
  font-weight: bolder;
  font-size: 2rem;
  color: #298eae;
}
.resoucePageContainer {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
